.widget-products {
    --font-size: var(--font-size-large);
    --padding-v: calc(var(--header-height) + var(--padding-m));
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --padding-v: calc(var(--header-height) + var(--padding-xs));
        --padding-h: var(--padding-s);
    }
}

.widget-products {
    padding: var(--padding-v) var(--padding-h);
    
    ul li {
        margin-bottom: var(--padding-l);
        
        a {
            @include basic-a();
            @include font-sans(1.2);

            font-size: var(--font-size);
            display: block;
            color: var(--primary-color);

            .name {
                margin-top: var(--padding-xs);
            }
        }
    }
    
    @media (min-width: $smartphone) {
        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: var(--padding-s);
            
            li {
                margin-bottom: var(--padding-xl);
            }

            a {
                .name {
                    margin-top: var(--padding-s);
                }
            }
        }
    }
}
