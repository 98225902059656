.win-message {
    --font-size-title: var(--font-size-large);
    --font-size: var(--font-size-small);
    --bg: var(--primary-bg);
    --color: var(--primary-color);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-s);
    --width: 50vw;
    
    @media (max-width: $smartphone) {
        --width: 90vw;
    }
}

.win-message {
    @include z-index($z-index-windows);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .overlay {
        background-color: var(--black30);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__wrapper {
        background: var(--bg);
        color: var(--color);
        border: 2px solid var(--color);
        border-radius: rem(20);
        padding: var(--padding-v) var(--padding-h);
        width: var(--width);
    
        position: absolute;
        z-index: 20;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, #{$z-index-windows}px);
    }

    h2 {
        @include font-sans();
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-s);
    }

    p {
        @include font-sans();
        font-size: var(--font-size);
    }

    button {
        @include basic-a();
        @include font-sans();

        padding: 0;
        outline: none;
        background-color: transparent;
        font-size: var(--font-size);
    }

    .controls {
        text-align: right;
        margin-top: var(--padding-m);
    }
}

// @media (max-width: $smartphone) {

// }
