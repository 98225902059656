:root {
    --white: #ffffff;
    --black: #060804;
    --black80: rgb(6, 8, 4, .8);
    --black30: rgb(6, 8, 4, .5);
    --grey: #999;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}