@mixin button {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --border: var(--primary-color);
    // --padding-v: var(--padding-xs);
    // --padding-h: var(--padding-s);
    --font-size-big: var(--font-size-base);
    --height: 3rem;

    @include font-sans();
    @include basic-a();

    align-items: center;
    appearance: none;
    background: var(--bg);
    border: 1px solid var(--border);
    cursor: pointer;
    color: var(--color);
    display: flex;
    font-size: var(--font-size-big);
    justify-content: center;
    height: var(--height);
    line-height: 1; //var(--height);
    // padding: var(--padding-v) var(--padding-h);
    padding: 0.1rem var(--padding-xs) 0;
    position: relative;
    text-align: center;
    vertical-align: top;
    border: 2px solid var(--color);
    border-radius: calc(var(--height) * 0.5);

    * { pointer-events: none; }
    span { display: block; }

    @include isCursor() {
        &:hover {
            --color: var(--primary-bg);
            --bg: var(--primary-color);
            --border: var(--primary-bg);
        }

        &.--alt:hover {
            opacity: .6;
        }
    }

    &.--alt {
        --color: var(--primary-bg);
        --bg: var(--primary-color);
        --border: var(--primary-color);
    }
}

.button {
    @include button;
}