.product-detail {
    --font-size: var(--font-size-large);
    --padding-v: var(--header-height);
    --padding-h: var(--padding-l);
    --max-height: 80vh;
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --padding-v: calc(var(--header-height) + var(--padding-xs));
        --padding-h: var(--padding-s);
        --max-height: 70vh;
    }
}

.product-detail {
    padding: var(--padding-v) var(--padding-h);
    
    .video {
        @include z-index(1);
        position: relative;
        height: var(--max-height);
        text-align: center;
        
        video {
            height: 100%;
            width: auto;
            object-fit: contain;
        }
    }    
    
    .background {
        @include z-index(0);
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }    
    
    @media (min-width: $smartphone) {
        // ul {
        //     display: grid;
        //     grid-template-columns: repeat(2, 1fr);
        //     gap: var(--padding-s);
            
        //     li {
        //         margin-bottom: var(--padding-xl);
        //     }

        //     a {
        //         .name {
        //             margin-top: var(--padding-s);
        //         }
        //     }
        // }

    }
}
