#Preloader {
    --font-size: var(--font-size-s);
    --bg: var(--primary-color);

    @media (max-width: $smartphone) {
        --font-size: 4vw;
    }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @include font-sans(1);
    font-size: var(--font-size);
    text-transform: uppercase;

    svg {
        width: rem(150);
        height: rem(60);
        fill: var(--primary-bg);
    }
}
