.widget-product {
    --font-size: var(--font-size-xxxsmall);
    --font-size-select: var(--font-size-xsmall);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
    --image-h: #{rem(500)};
    --image-w: #{rem(320)};
    --width: #{rem(320)};
    --pos: #{rem(20)};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxsmall);
        --font-size-select: var(--font-size-xsmall);
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --image-h: 55vh;
        --image-w: 60vw;
        --width: 90vw;
        --pos: 5vw;
    }
}

.widget-product {
    position: fixed;
    right: var(--pos); 
    bottom: var(--pos); 
    width: var(--width);
    
    &__wrapper {
        border: 2px solid var(--primary-color);
        background-color: var(--primary-bg);
        border-radius: rem(20);
        padding: var(--padding-v) var(--padding-h);

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .button {
        width: 100%;
    }

    .variants {
        @include font-sans();
        font-size: var(--font-size-select);
        margin-bottom: var(--padding-xs);
        width: 100%; //calc(100% - #{rem(15)} - var(--image-w));
        
        p {
            margin-bottom: var(--padding-xxxs); 
            padding-left: rem(10);
            font-size: var(--font-size);
        }

        .wrapper {
            position: relative;

            &::after {
                content: "↓";
                position: absolute;
                right: var(--padding-xs);
                top: 50%;
                transform: translateY(-50%);
            }
        }
        
        select {
            appearance: none;
            background-color: transparent;
            outline: none;
            cursor: pointer;
    
            padding: var(--padding-xxs) var(--padding-xs);
            border: 1px solid var(--primary-color);
            border-radius: rem(25);
            width: 100%;
        }
    }

    .images {
        margin: 0 auto var(--padding-xs);
        display: flex;
        justify-content: flex-end;
        height: var(--image-h);
        width: var(--image-w);
        
        figure {
            border: 2px solid var(--primary-color);
            overflow: hidden;
            border-radius: rem(20);
            height: var(--image-h);
            width: var(--image-w);

            &.--hidden {
                display: none;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}
